<template>
  <div class="row">
    <div class="col s12 m6">
      <div class="card blue-grey darken-1">
        <div class="card-content white-text">
          <span class="card-title">Привет!</span>
          <blockquote>Здесь вряд ли что можно найти. Сайт находится в разработке.</blockquote>
          <p>
            Для связи, переведи любую сумму. В назначении платежа
            можешь написать все что угодно. От предложений, до любых слов
            великого русского. Что не запрещено. :)
          </p>
          <br />
          <div>Удачи в поисках интересного, хорошего, познавательного!</div>
        </div>
        <div class="card-action">
          <a href="https://profcad.ru">Технологии в жизни и в работе</a>
          <a href="https://ggddd.ru">Простой генератор паролей</a>
        </div>
      </div>
      <iframe
        v-bind="{ src: src_ }"
        width="423"
        height="227"
        frameborder="0"
        allowtransparency="true"
        scrolling="no"
      ></iframe>
    </div>
  </div>

</template>


<script>
import "materialize-css/dist/css/materialize.css";

export default {
  data() {
    return {
      // src_: "https://yoomoney.ru/quickpay/shop-widget?writer=buyer&targets=Задать вопрос&targets-hint=Залать вопрос.&default-sum=100&button-text=14&hint=&successURL=&quickpay=shop&account=410013800273366&",
      src_: "https://yoomoney.ru/quickpay/shop-widget?writer=buyer&targets=Задать вопрос&targets-hint=Залать вопрос.&default-sum=100&button-text=14&payment-type-choice=on&mobile-payment-type-choice=on&hint=&successURL=&quickpay=shop&account=410013800273366&"
    };
  },
};
</script>


<style lang="scss">
</style>
